function IconPayFail() {
    return (
        <svg width="1em" height="1em" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg" className="mb-5 text-[90px]">
            <path
                d="M40.8485 29.4868C40.6623 27.0665 42.5759 25 45.0033 25C47.4307 25 49.3444 27.0665 49.1582 29.4867L47.6951 48.5074C47.5869 49.9139 46.414 51 45.0033 51C43.5926 51 42.4198 49.9139 42.3116 48.5074L40.8485 29.4868Z"
                fill="#DA1E1A"
            ></path>
            <path
                d="M49.0034 60C49.0034 62.2091 47.2125 64 45.0034 64C42.7943 64 41.0034 62.2091 41.0034 60C41.0034 57.7909 42.7943 56 45.0034 56C47.2125 56 49.0034 57.7909 49.0034 60Z"
                fill="#DA1E1A"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M45 0C69.8528 0 90 20.1472 90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0ZM45 7C24.0132 7 7 24.0132 7 45C7 65.9868 24.0132 83 45 83C65.9868 83 83 65.9868 83 45C83 24.0132 65.9868 7 45 7Z"
                fill="#DA1E1A"
            ></path>
        </svg>
    );
}

export default IconPayFail;
