import request from '~/utils';

export const requestLoginAccountID = async (data) => {
    try {
        const res = await request.post('/auth/player_id_login', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestLoginGarena = async (data) => {
    try {
        const res = await request.post('/auth/player_garena_login', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
