import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import Background from './Background';
import IconArrowUp from '~/assets/icon/IconArrowUp';
import IconArrowDown from '~/assets/icon/IconArrowDown';
import IconCheckGame from '~/assets/icon/IconCheckGame';
import { dispatchCurrentApps } from '~/redux/reducer/app';

function ListGame({ data }) {
    const [show, setShow] = useState(false);
    const [checked, setChecked] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();

    const app = searchParams.get('app');
    const amount = searchParams.get('amount');
    const channel = searchParams.get('channel');

    const dispatch = useDispatch();

    const handleSelectGame = (index, apps) => {
        let params = {
            app: apps.app_code,
        };
        if (channel) {
            params.channel = channel;
        }
        if (amount) {
            params.amount = amount;
        }

        setChecked(index);
        setSearchParams(params);
        dispatch(dispatchCurrentApps(apps));
    };

    return (
        <div className="relative w-full">
            <Background />

            <div className="relative mx-auto flex flex-col px-[22px] pb-[14px] pt-5 md:max-w-5xl md:px-8 md:pb-4 md:pt-[27px]">
                <div className="relative -ms-1.5 mb-4 text-lg/none font-bold text-text-title md:mb-5 md:ms-0 md:text-xl/none">
                    Chọn game để nạp
                </div>

                <div className="grid grid-cols-4 gap-x-[22px] gap-y-4 sm:grid-cols-6 lg:grid-cols-8">
                    {data.map((game, index) => (
                        <div
                            key={index}
                            className="cursor-pointer outline-none"
                            data-headlessui-state={app === game.app_code ? 'checked' : ''}
                            onClick={() => handleSelectGame(index, game)}
                        >
                            <div className="mx-auto max-w-[70px] md:max-w-[105px]">
                                <div className="mb-1 px-[3px] md:mb-2 md:px-2">
                                    <div className="relative">
                                        <div className="relative overflow-hidden rounded-[25%] border-[3px] border-transparent transition-colors ui-checked:border-primary-red md:border-4">
                                            <div className="relative pt-[100%]">
                                                <img
                                                    className="pointer-events-none absolute inset-0 h-full w-full bg-white object-cover"
                                                    src={game.avatar_url}
                                                    alt={game.title}
                                                />
                                            </div>
                                        </div>

                                        <div
                                            className={`absolute inset-0 origin-top-left scale-50 rounded-ss-[50%] p-[18.75%] opacity-0 transition-opacity ui-checked:opacity-100 ${
                                                index === checked ? 'game-checked' : ''
                                            }`}
                                        >
                                            <IconCheckGame />
                                        </div>
                                    </div>
                                </div>
                                <div className="line-clamp-2 text-center text-xs ui-checked:font-bold ui-checked:text-primary-red md:text-sm/[22px]">
                                    {game.title}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <button
                    className="-mb-1.5 mt-1 flex cursor-pointer self-center px-3 py-2 text-sm/none font-medium text-primary-red"
                    onClick={() => setShow(!show)}
                >
                    {show ? 'Thu gọn' : 'Xem thêm'}
                    {show ? <IconArrowUp /> : <IconArrowDown />}
                </button>
            </div>
        </div>
    );
}

export default ListGame;
