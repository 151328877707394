import { Fragment } from 'react';

function Background() {
    return (
        <Fragment>
            <div
                className="absolute inset-0 bg-[#EFEFEF] bg-cover bg-center bg-no-repeat rtl:-scale-x-100 md:bg-contain"
                role="none"
                style={{
                    backgroundImage: "url('https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/pattern-game-selection-59889447.png')",
                }}
            ></div>

            <div className="pointer-events-none absolute inset-0 flex rtl:-scale-x-100 rtl:flex-row-reverse" role="none">
                <div className="h-[7px] flex-1 bg-[#F2B13E]" role="none"></div>
                <svg
                    width="390"
                    height="27"
                    viewBox="0 0 390 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-[27px] md:hidden"
                    preserveAspectRatio="xMidYMin meet"
                    role="none"
                >
                    <path d="M390 0H0V7H285L301 27H390V0Z" fill="url(#paint0_linear_2330_34259)" role="none"></path>
                    <mask
                        id="mask0_2330_34259"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="390"
                        height="27"
                        role="none"
                        style={{ maskType: 'alpha' }}
                    >
                        <path d="M390 0H0V7H285L301 27H390V0Z" fill="url(#paint1_linear_2330_34259)" role="none"></path>
                    </mask>
                    <g mask="url(#mask0_2330_34259)" role="none">
                        <rect
                            x="-15.0254"
                            y="72.4863"
                            width="110.997"
                            height="3"
                            transform="rotate(-45 -15.0254 72.4863)"
                            fill="url(#paint2_linear_2330_34259)"
                            role="none"
                        ></rect>
                        <rect
                            opacity="0.5"
                            x="232.053"
                            y="58.1582"
                            width="110.997"
                            height="25.9753"
                            transform="rotate(-47 232.053 58.1582)"
                            fill="url(#paint3_linear_2330_34259)"
                            role="none"
                        ></rect>
                        <rect
                            opacity="0.3"
                            x="298.977"
                            y="69.4863"
                            width="110.997"
                            height="6.3044"
                            transform="rotate(-45 298.977 69.4863)"
                            fill="url(#paint4_linear_2330_34259)"
                            role="none"
                        ></rect>
                        <path
                            opacity="0.5"
                            d="M192.334 72.0098L268.034 -9.16811L278.223 -7.40131L202.523 73.7766L192.334 72.0098Z"
                            fill="url(#paint5_linear_2330_34259)"
                            role="none"
                        ></path>
                        <rect
                            opacity="0.15"
                            x="-21"
                            y="123.275"
                            width="179.995"
                            height="4.38032"
                            transform="rotate(-45 -21 123.275)"
                            fill="url(#paint6_linear_2330_34259)"
                            role="none"
                        ></rect>
                    </g>
                    <defs role="none">
                        <linearGradient
                            id="paint0_linear_2330_34259"
                            x1="-9"
                            y1="7.61906"
                            x2="387.828"
                            y2="41.0361"
                            gradientUnits="userSpaceOnUse"
                            role="none"
                        >
                            <stop stopColor="#F2B13E" role="none"></stop>
                            <stop offset="1" stopColor="#FDD373" stopOpacity="0.63" role="none"></stop>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_2330_34259"
                            x1="27"
                            y1="15.2381"
                            x2="388.472"
                            y2="38.7377"
                            gradientUnits="userSpaceOnUse"
                            role="none"
                        >
                            <stop stopColor="#F3A00C" role="none"></stop>
                            <stop offset="1" stopColor="#FFBB21" stopOpacity="0.76" role="none"></stop>
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_2330_34259"
                            x1="9.0067"
                            y1="75.3242"
                            x2="64.1695"
                            y2="74.4301"
                            gradientUnits="userSpaceOnUse"
                            role="none"
                        >
                            <stop stopColor="#DB910B" stopOpacity="0" role="none"></stop>
                            <stop offset="1" stopColor="#F09F0B" role="none"></stop>
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear_2330_34259"
                            x1="295.701"
                            y1="78.6918"
                            x2="318.228"
                            y2="69.5067"
                            gradientUnits="userSpaceOnUse"
                            role="none"
                        >
                            <stop stopColor="#DE9611" stopOpacity="0" role="none"></stop>
                            <stop offset="1" stopColor="#F79F00" role="none"></stop>
                        </linearGradient>
                        <linearGradient
                            id="paint4_linear_2330_34259"
                            x1="323.009"
                            y1="75.4501"
                            x2="378.183"
                            y2="75.0245"
                            gradientUnits="userSpaceOnUse"
                            role="none"
                        >
                            <stop stopColor="#DE9611" stopOpacity="0" role="none"></stop>
                            <stop offset="1" stopColor="#F79F00" role="none"></stop>
                        </linearGradient>
                        <linearGradient
                            id="paint5_linear_2330_34259"
                            x1="218.794"
                            y1="56.0898"
                            x2="255.761"
                            y2="15.1365"
                            gradientUnits="userSpaceOnUse"
                            role="none"
                        >
                            <stop stopColor="#DE9611" stopOpacity="0" role="none"></stop>
                            <stop offset="1" stopColor="#F79F00" role="none"></stop>
                        </linearGradient>
                        <linearGradient
                            id="paint6_linear_2330_34259"
                            x1="17.9709"
                            y1="127.419"
                            x2="83.65"
                            y2="126.721"
                            gradientUnits="userSpaceOnUse"
                            role="none"
                        >
                            <stop stopColor="#F79F00" role="none"></stop>
                            <stop offset="1" stopColor="#DE9611" stopOpacity="0" role="none"></stop>
                        </linearGradient>
                    </defs>
                </svg>
                <svg
                    width="1024"
                    height="27"
                    viewBox="0 0 1024 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="hidden h-[27px] md:block"
                    preserveAspectRatio="xMidYMin meet"
                    role="none"
                >
                    <path d="M1024 0H0V7H516L532 27H1024V0Z" fill="url(#paint0_linear_2339_34301)" role="none"></path>
                    <mask
                        id="mask0_2339_34301"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="1024"
                        height="27"
                        role="none"
                        style={{ maskType: 'alpha' }}
                    >
                        <path d="M1024 0H0V7H516L532 27H1024V0Z" fill="url(#paint1_linear_2339_34301)" role="none"></path>
                    </mask>
                    <g mask="url(#mask0_2339_34301)" role="none">
                        <rect
                            x="215.977"
                            y="72.4844"
                            width="110.997"
                            height="3"
                            transform="rotate(-45 215.977 72.4844)"
                            fill="url(#paint2_linear_2339_34301)"
                            role="none"
                        ></rect>
                        <rect
                            opacity="0.5"
                            x="463.055"
                            y="58.1562"
                            width="110.997"
                            height="25.9753"
                            transform="rotate(-47 463.055 58.1562)"
                            fill="url(#paint3_linear_2339_34301)"
                            role="none"
                        ></rect>
                        <rect
                            opacity="0.5"
                            x="561.977"
                            y="69.4844"
                            width="110.997"
                            height="3"
                            transform="rotate(-45 561.977 69.4844)"
                            fill="url(#paint4_linear_2339_34301)"
                            role="none"
                        ></rect>
                        <path
                            opacity="0.5"
                            d="M423.336 72.0078L499.036 -9.17006L509.225 -7.40327L433.525 73.7746L423.336 72.0078Z"
                            fill="url(#paint5_linear_2339_34301)"
                            role="none"
                        ></path>
                        <rect
                            opacity="0.15"
                            x="210"
                            y="123.273"
                            width="179.995"
                            height="4.38032"
                            transform="rotate(-45 210 123.273)"
                            fill="url(#paint6_linear_2339_34301)"
                            role="none"
                        ></rect>
                    </g>
                    <defs role="none">
                        <linearGradient
                            id="paint0_linear_2339_34301"
                            x1="222"
                            y1="7.61902"
                            x2="618.827"
                            y2="41.0361"
                            gradientUnits="userSpaceOnUse"
                            role="none"
                        >
                            <stop stopColor="#F2B13E" role="none"></stop>
                            <stop offset="1" stopColor="#FDD373" stopOpacity="0.63" role="none"></stop>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_2339_34301"
                            x1="258.001"
                            y1="15.2381"
                            x2="619.473"
                            y2="38.7377"
                            gradientUnits="userSpaceOnUse"
                            role="none"
                        >
                            <stop stopColor="#F3A00C" role="none"></stop>
                            <stop offset="1" stopColor="#FFBB21" stopOpacity="0.76" role="none"></stop>
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_2339_34301"
                            x1="240.009"
                            y1="75.3223"
                            x2="295.171"
                            y2="74.4282"
                            gradientUnits="userSpaceOnUse"
                            role="none"
                        >
                            <stop stopColor="#DB910B" stopOpacity="0" role="none"></stop>
                            <stop offset="1" stopColor="#F09F0B" role="none"></stop>
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear_2339_34301"
                            x1="526.703"
                            y1="78.6898"
                            x2="549.23"
                            y2="69.5047"
                            gradientUnits="userSpaceOnUse"
                            role="none"
                        >
                            <stop stopColor="#DE9611" stopOpacity="0" role="none"></stop>
                            <stop offset="1" stopColor="#F79F00" role="none"></stop>
                        </linearGradient>
                        <linearGradient
                            id="paint4_linear_2339_34301"
                            x1="586.009"
                            y1="72.3223"
                            x2="641.171"
                            y2="71.4282"
                            gradientUnits="userSpaceOnUse"
                            role="none"
                        >
                            <stop stopColor="#DE9611" stopOpacity="0" role="none"></stop>
                            <stop offset="1" stopColor="#F79F00" role="none"></stop>
                        </linearGradient>
                        <linearGradient
                            id="paint5_linear_2339_34301"
                            x1="449.796"
                            y1="56.0878"
                            x2="486.763"
                            y2="15.1345"
                            gradientUnits="userSpaceOnUse"
                            role="none"
                        >
                            <stop stopColor="#DE9611" stopOpacity="0" role="none"></stop>
                            <stop offset="1" stopColor="#F79F00" role="none"></stop>
                        </linearGradient>
                        <linearGradient
                            id="paint6_linear_2339_34301"
                            x1="248.971"
                            y1="127.417"
                            x2="314.65"
                            y2="126.719"
                            gradientUnits="userSpaceOnUse"
                            role="none"
                        >
                            <stop stopColor="#F79F00" role="none"></stop>
                            <stop offset="1" stopColor="#DE9611" stopOpacity="0" role="none"></stop>
                        </linearGradient>
                    </defs>
                </svg>
                <div className="h-[27px] flex-1 bg-[#FDD373] opacity-[0.63]" role="none"></div>
            </div>
        </Fragment>
    );
}

export default Background;
