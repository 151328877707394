import { createSlice } from '@reduxjs/toolkit';

const moduleReducer = createSlice({
    name: 'module',
    initialState: {
        loading: false,
    },
    reducers: {
        dispatchStartLoading: (state) => {
            state.loading = true;
        },
        dispatchStopLoading: (state) => {
            state.loading = false;
        },
    },
});

export const { dispatchStartLoading, dispatchStopLoading } = moduleReducer.actions;

export default moduleReducer.reducer;
