import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './data.css';
import './login.css';
import './style.css';
import './index.css';
import './custom.css';
import './loading.css';

import publicRoutes from './routes';
import Loading from './components/Loading';
import DefaultLayout from './layouts/DefaultLayout';

function App() {
    const { loading } = useSelector((state) => state.module);

    return (
        <Router>
            <Fragment>
                {loading && <Loading />}
                <Routes>
                    {publicRoutes.map((route, index) => {
                        let Layout = DefaultLayout;

                        if (route.layout) {
                            Layout = route.layout;
                        } else if (route.layout === null) {
                            Layout = Fragment;
                        }

                        const Page = route.component;
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <Layout>
                                        <Page />
                                    </Layout>
                                }
                            />
                        );
                    })}
                </Routes>
            </Fragment>
        </Router>
    );
}

export default App;
