import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { logoutGarenaSuccess } from '~/redux/reducer/auth';

function LoginGarena() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { search } = useLocation();

    const { auth_garena } = useSelector((state) => state.auth);

    const handleToggleModalLogout = () => {
        const modal = document.getElementById('modal-logout-id');
        modal.classList.toggle('hidden');
    };

    const handleLogoutGarena = async () => {
        dispatch(logoutGarenaSuccess());
        handleToggleModalLogout();
    };

    const handleClickLoginGarena = async () => {
        const encodedRedirectURL = encodeURIComponent(search);
        navigate(`/garena-login?redirect=/${encodedRedirectURL}`);
    };

    return (
        <div className="group md:max-w-[464px]">
            <div className="mb-3 flex items-center gap-2 text-lg/none text-text-title md:text-xl/none">
                <div className="grid items-center">
                    <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="col-start-1 row-start-1 text-2xl text-primary-red"
                    >
                        <path
                            d="M0 3C0 1.34315 1.34315 0 3 0H21C22.6569 0 24 1.34315 24 3V15.7574C24 16.553 23.6839 17.3161 23.1213 17.8787L17.8787 23.1213C17.3161 23.6839 16.553 24 15.7574 24H3C1.34315 24 0 22.6569 0 21V3Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                    <div className="col-start-1 row-start-1 text-center text-base/none font-bold text-white">1</div>
                </div>
                <span className="font-bold">Đăng nhập</span>

                {auth_garena && (
                    <Fragment>
                        <div className="fixed inset-0 z-50 hidden" id="modal-logout-id">
                            <div className="absolute inset-0 bg-black/60"></div>
                            <div className="absolute inset-0 grid overflow-auto justify-items-center items-center">
                                <div className="" id="headlessui-dialog-panel-:r2l:" data-headlessui-state="open">
                                    <div className="w-screen p-2">
                                        <div className="relative mx-auto flex w-full max-w-80 flex-col gap-5 rounded-lg bg-white p-6 text-center">
                                            <div className="flex flex-col gap-3">
                                                <h2
                                                    className="text-base/none font-bold text-text-title"
                                                    id="headlessui-dialog-title-:r2m:"
                                                    data-headlessui-state="open"
                                                >
                                                    Bạn muốn đăng xuất?
                                                </h2>
                                                <p
                                                    className="text-sm/[22px] text-text-secondary"
                                                    id="headlessui-description-:r2n:"
                                                    data-headlessui-state="open"
                                                >
                                                    Đăng xuất khỏi tài khoản hiện tại
                                                </p>
                                            </div>
                                            <div className="flex gap-4">
                                                <button
                                                    className="inline-flex items-center justify-center gap-1.5 rounded-md border py-1 text-center leading-none transition-colors border-primary-red text-primary-red hover:bg-white-hover bg-white px-5 text-sm font-bold h-10 w-full"
                                                    onClick={handleToggleModalLogout}
                                                >
                                                    Quay lại
                                                </button>
                                                <button
                                                    className="inline-flex items-center justify-center gap-1.5 rounded-md border py-1 text-center leading-none transition-colors border-primary-red bg-primary-red text-white hover:bg-primary-red-hover hover:border-primary-red-hover px-5 text-sm font-bold h-10 w-full"
                                                    onClick={handleLogoutGarena}
                                                >
                                                    Đăng xuất
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button
                            className="logout-button ms-auto flex items-center text-sm/none text-primary-red transition-opacity hover:opacity-70 group-[.loading]:pointer-events-none group-[.loading]:opacity-50"
                            onClick={handleToggleModalLogout}
                        >
                            <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 80 80"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="me-1"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M53.048 11.8069C51.8367 10.6764 49.9383 10.7418 48.8078 11.953C47.6773 13.1643 47.7428 15.0626 48.954 16.1932L58.3898 25H14.0007C12.3439 25 11.0007 26.3432 11.0007 28C11.0007 29.6569 12.3439 31 14.0007 31H66.0007C67.233 31 68.3399 30.2465 68.7917 29.1001C69.2436 27.9538 68.9485 26.6476 68.0477 25.8069L53.048 11.8069ZM26.9539 68.1932C28.1652 69.3237 30.0636 69.2582 31.1941 68.0469C32.3245 66.8356 32.259 64.9373 31.0477 63.8068L21.6114 55H66.0001C67.657 55 69.0001 53.6569 69.0001 52C69.0001 50.3432 67.657 49 66.0001 49H14.0001C12.7679 49 11.6609 49.7535 11.2091 50.8999C10.7572 52.0464 11.0524 53.3525 11.9532 54.1932L26.9539 68.1932Z"
                                    fill="currentColor"
                                ></path>
                            </svg>
                            Đăng xuất
                        </button>
                    </Fragment>
                )}
            </div>

            <div className="group-[.loading]:pointer-events-none group-[.loading]:opacity-50">
                {auth_garena ? (
                    <div id="loginSection" className="rounded-md bg-[#F7F7F7] p-4 outline outline-1 -outline-offset-1 outline-line">
                        <div className="flex items-start gap-2">
                            <img
                                src="https://cdngarenanow-a.akamaihd.net/gxx/resource/avatar/0.jpg"
                                alt={auth_garena.username}
                                className="w-10 h-10 rounded-full border border-gray-300"
                            />
                            <div className="flex flex-col">
                                <span className="player-name font-bold">{auth_garena.username}</span>
                                <span className="player-id text-xs text-text-secondary">ID: {auth_garena.id}</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="rounded-md bg-[#F7F7F7] p-4 outline outline-1 -outline-offset-1 outline-line">
                        <div className="flex items-center gap-4 text-xs/normal text-text-secondary md:text-sm/[22px]">
                            <span className="me-auto">Đăng nhập bằng tài khoản Garena</span>
                            <div
                                className="shrink-0 rounded-full p-1.5 transition-opacity hover:opacity-70 outline outline-1 -outline-offset-1 outline-line bg-white cursor-pointer"
                                onClick={handleClickLoginGarena}
                            >
                                <img
                                    className="h-5 w-5"
                                    src="https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/ic-garena-2fce3e76.svg"
                                    alt="Garena logo"
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default LoginGarena;
