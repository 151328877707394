import { Link } from 'react-router-dom';

function NotFound() {
    return (
        <main className="flex flex-1 flex-col dark:bg-theme-dark">
            <div className="mx-auto flex h-full w-full max-w-6xl items-center justify-center p-6">
                <div className="flex flex-col items-center text-center">
                    <div className="mb-5 inline-block text-[90px]">
                        <svg width="1em" height="1em" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M40.8485 29.4868C40.6623 27.0665 42.5759 25 45.0033 25C47.4307 25 49.3444 27.0665 49.1582 29.4867L47.6951 48.5074C47.5869 49.9139 46.414 51 45.0033 51C43.5926 51 42.4198 49.9139 42.3116 48.5074L40.8485 29.4868Z"
                                fill="#DA1E1A"
                            ></path>
                            <path
                                d="M49.0034 60C49.0034 62.2091 47.2125 64 45.0034 64C42.7943 64 41.0034 62.2091 41.0034 60C41.0034 57.7909 42.7943 56 45.0034 56C47.2125 56 49.0034 57.7909 49.0034 60Z"
                                fill="#DA1E1A"
                            ></path>
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M45 0C69.8528 0 90 20.1472 90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0ZM45 7C24.0132 7 7 24.0132 7 45C7 65.9868 24.0132 83 45 83C65.9868 83 83 65.9868 83 45C83 24.0132 65.9868 7 45 7Z"
                                fill="#DA1E1A"
                            ></path>
                        </svg>
                    </div>
                    <div className="px-4 text-sm/[22px] text-text-secondary mb-5">Trang yêu cầu không tìm thấy trên máy chủ này.</div>
                    <div className="center">
                        <Link to="/">
                            <button
                                type="button"
                                className="logout-button ms-auto flex items-center text-sm/none text-primary-red transition-opacity hover:opacity-70 group-[.loading]:pointer-events-none group-[.loading]:opacity-50"
                            >
                                <svg
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 80 80"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="me-1"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M53.048 11.8069C51.8367 10.6764 49.9383 10.7418 48.8078 11.953C47.6773 13.1643 47.7428 15.0626 48.954 16.1932L58.3898 25H14.0007C12.3439 25 11.0007 26.3432 11.0007 28C11.0007 29.6569 12.3439 31 14.0007 31H66.0007C67.233 31 68.3399 30.2465 68.7917 29.1001C69.2436 27.9538 68.9485 26.6476 68.0477 25.8069L53.048 11.8069ZM26.9539 68.1932C28.1652 69.3237 30.0636 69.2582 31.1941 68.0469C32.3245 66.8356 32.259 64.9373 31.0477 63.8068L21.6114 55H66.0001C67.657 55 69.0001 53.6569 69.0001 52C69.0001 50.3432 67.657 49 66.0001 49H14.0001C12.7679 49 11.6609 49.7535 11.2091 50.8999C10.7572 52.0464 11.0524 53.3525 11.9532 54.1932L26.9539 68.1932Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                                Quay lại
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default NotFound;
