import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

function PaymentMethod({ data }) {
    const [checked, setChecked] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const app = searchParams.get('app');
    const amount = searchParams.get('amount');
    const channelCode = searchParams.get('channel');

    const handleSelectPayment = (channel, index) => {
        let params = {
            app: app || data.app_code,
            channel: channel.code,
        };
        if (amount) {
            params.amount = amount;
        }

        setChecked(index);
        setSearchParams(params);
    };

    return (
        <div className="flex flex-col">
            <div className="mb-3 flex scroll-mt-36 items-center gap-2 text-lg/none font-bold text-text-title md:text-xl/none">
                <div className="grid items-center">
                    <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="col-start-1 row-start-1 text-2xl text-primary-red"
                    >
                        <path
                            d="M0 3C0 1.34315 1.34315 0 3 0H21C22.6569 0 24 1.34315 24 3V15.7574C24 16.553 23.6839 17.3161 23.1213 17.8787L17.8787 23.1213C17.3161 23.6839 16.553 24 15.7574 24H3C1.34315 24 0 22.6569 0 21V3Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                    <div className="col-start-1 row-start-1 text-center text-base/none text-white">2</div>
                </div>
                <div>Phương thức thanh toán</div>
            </div>

            <div className="grid grid-cols-2 gap-2.5 md:grid-cols-3 md:gap-4">
                {data.channels.map((channel, index) => (
                    <div
                        className="relative scroll-mt-40"
                        key={index}
                        data-headlessui-state={index === checked || channelCode === channel.code ? 'checked' : ''}
                        onClick={() => handleSelectPayment(channel, index)}
                    >
                        <div className="peer relative flex h-full min-h-[80px] cursor-pointer items-start gap-2 rounded-md p-2.5 pb-2 outline outline-1 -outline-offset-1 outline-box-border ui-checked:bg-selected-bg ui-checked:outline-2 ui-checked:-outline-offset-2 ui-checked:outline-primary-red ui-disabled:bg-[#F4F4F4] ui-disabled:outline-none max-md:flex-col max-md:justify-center md:items-center md:gap-3 md:p-3">
                            <img
                                className="mx-auto pointer-events-none h-10 w-full object-contain object-center rtl:object-right max-md:shrink max-md:grow md:h-14"
                                src={channel.image_url}
                                alt={channel.name}
                            />
                            <div className="absolute end-[3px] top-[3px] overflow-hidden rounded-[3px]">
                                <div className="flex text-2xs/none font-bold uppercase">
                                    <div className="flex items-center gap-1 bg-primary-red p-0.5 pe-1 text-white">
                                        <img
                                            className="denomIcon h-3 w-3 rounded-sm bg-white object-contain p-0.5"
                                            src={data.bonus_icon}
                                            alt={data.title}
                                        />
                                        Bonus
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PaymentMethod;
