function IconPaySuccess() {
    return (
        <svg width="1em" height="1em" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg" className="mb-5 text-[90px]">
            <g clipPath="url(#clip0_1200_20221)">
                <path
                    d="M45 0C56.9774 0 67.8619 4.6794 75.925 12.3098L70.539 16.8614C63.792 10.734 54.832 7 45 7C24.0132 7 7 24.0132 7 45C7 65.9868 24.0132 83 45 83C65.9868 83 83 65.9868 83 45C83 38.173 81.1997 31.7666 78.0479 26.2294L82.8327 20.6244C87.3679 27.6487 90 36.017 90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0Z"
                    fill="url(#paint0_linear_1200_20221)"
                ></path>
                <path
                    d="M26.674 42.1661L40.2705 59.7618C41.1683 60.9236 42.9017 60.9816 43.8751 59.8822L83.2346 15.4295C83.9457 14.6263 83.9094 13.4085 83.1517 12.6491C82.3925 11.8883 81.1721 11.8508 80.3677 12.5635L41.5 46.9997L31.1913 37.9281C29.9592 36.8437 28.0966 36.9031 26.9359 38.0637C25.8291 39.1706 25.7169 40.9275 26.674 42.1661Z"
                    fill="url(#paint1_linear_1200_20221)"
                ></path>
            </g>
            <defs>
                <linearGradient id="paint0_linear_1200_20221" x1="44.8584" y1="0" x2="44.8584" y2="90" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#34D17D"></stop>
                    <stop offset="1" stopColor="#21C46D"></stop>
                </linearGradient>
                <linearGradient id="paint1_linear_1200_20221" x1="44.8584" y1="0" x2="44.8584" y2="90" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#34D17D"></stop>
                    <stop offset="1" stopColor="#21C46D"></stop>
                </linearGradient>
                <clipPath id="clip0_1200_20221">
                    <rect width="90" height="90" fill="white"></rect>
                </clipPath>
            </defs>
        </svg>
    );
}

export default IconPaySuccess;
