import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Amount from '~/components/Amount';
import Banner from '~/components/Banner';
import Sticky from '~/components/Sticky';
import ListGame from '~/components/ListGame';
import LoadClam from '~/components/LoadClam';
import { requestGetApps } from '~/services/app';
import PaymentMethod from '~/components/PaymentMethod';
import { dispatchCurrentApps } from '~/redux/reducer/app';
import LoginGarena from '~/layouts/components/LoginGarena';
import LoginAccount from '~/layouts/components/LoginAccount';
import { dispatchStartLoading, dispatchStopLoading } from '~/redux/reducer/module';

function Home() {
    const [apps, setApps] = useState([]);
    const [code, setCode] = useState('');
    const [serial, setSerial] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();

    const app = searchParams.get('app');
    const amount = searchParams.get('amount');
    const channel = searchParams.get('channel');

    const { current_apps } = useSelector((state) => state.app);
    const { banner_url } = useSelector((state) => state.setting);

    const dispatch = useDispatch();

    useEffect(() => {
        const fetch = async () => {
            dispatch(dispatchStartLoading());
            const result = await requestGetApps();

            dispatch(dispatchStopLoading());
            if (result?.status === 200) {
                setApps(result.data);

                if (!app) {
                    const currentApps = result.data[0];
                    dispatch(dispatchCurrentApps(currentApps));
                    setSearchParams({ app: result.data[0].app_code });
                }
            } else {
                localStorage.removeItem('_re');
                window.location.reload();
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <main className="flex flex-1 flex-col dark:bg-theme-dark">
            <div className="mb-5 flex h-full flex-col md:mb-12">
                {banner_url && <Banner banner_url={banner_url} />}

                <div className="bg-[#efefef]">
                    {apps.length > 0 && <ListGame data={apps} />}

                    <div className="rounded-t-[14px] bg-white lg:rounded-none">
                        <div className="mx-auto max-w-5xl p-2 pb-4 lg:px-10 lg:pt-9">
                            {current_apps && <LoadClam apps={current_apps} />}

                            <div className="flex flex-col gap-9 px-2 lg:px-0">
                                {current_apps?.login_type === 'account_id' ? <LoginAccount /> : <LoginGarena />}
                                {current_apps && <PaymentMethod data={current_apps} />}
                                {current_apps && (
                                    <Amount
                                        data={current_apps}
                                        inputCode={code}
                                        setInputCode={setCode}
                                        inputSerial={serial}
                                        setInputSerial={setSerial}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {app && channel && amount && <Sticky data={current_apps} channel={channel} amount={amount} code={code} serial={serial} />}
        </main>
    );
}

export default Home;
