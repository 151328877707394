function AvatarUser() {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="h-full w-full"
            aria-label="Default avatar"
        >
            <circle cx="40" cy="40" r="40" fill="#C8C8C8"></circle>
            <circle cx="40" cy="25.6694" r="14" fill="white"></circle>
            <path
                d="M18.6624 60.6252C16.2799 58.0468 17.02 54.0297 20.0672 52.2863C25.9526 48.9192 32.7514 46.9976 39.9935 46.9976C47.2356 46.9976 54.0345 48.9192 59.9198 52.2863C62.967 54.0297 63.7072 58.0468 61.3246 60.6252C55.9949 66.3929 48.41 69.9976 39.9935 69.9976C31.5771 69.9976 23.9921 66.3929 18.6624 60.6252Z"
                fill="white"
            ></path>
        </svg>
    );
}

export default AvatarUser;
