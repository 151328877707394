import { createSlice } from '@reduxjs/toolkit';

const authReducer = createSlice({
    name: 'auth',
    initialState: {
        auth_account: null,
        auth_garena: null,
    },
    reducers: {
        loginAccountSuccess: (state, action) => {
            state.auth_account = action.payload;
        },
        logoutAccountSuccess: (state) => {
            state.auth_account = null;
        },
        loginGarenaSuccess: (state, action) => {
            state.auth_garena = action.payload;
        },
        logoutGarenaSuccess: (state) => {
            state.auth_garena = null;
        },
    },
});

export const { loginAccountSuccess, logoutAccountSuccess, loginGarenaSuccess, logoutGarenaSuccess } = authReducer.actions;

export default authReducer.reducer;
