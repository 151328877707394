function Banner({ banner_url }) {
    return (
        <div className="bg-[#151515]">
            <div className="group mx-auto w-full max-w-[1366px] md:py-2.5 lg:py-5">
                <div className="bannerWrapper relative flex justify-center pt-[43.478%] md:pt-[19.106%]">
                    <div className="scrollbar-none absolute inset-0 flex overflow-auto justify-center">
                        <a
                            className="block h-full w-full shrink-0 snap-center md:w-[50.577%] md:rounded-xl focus-visible:outline focus-visible:outline-2 focus-visible:-outline-offset-2"
                            target="_blank"
                            rel="noreferrer"
                            href="https://shopee.vn/m/pointxtermgamejan2024"
                        >
                            <img
                                className="pointer-events-none h-full w-full object-contain transition-all md:rounded-xl"
                                src={banner_url}
                                alt="BANNER"
                            />
                        </a>
                    </div>
                    <div className="dark:md:from-theme-dark pointer-events-none absolute inset-y-0 hidden w-[21.783%] items-center from-[#151515] md:flex start-0 justify-end bg-gradient-to-r rtl:bg-gradient-to-l"></div>
                    <div className="dark:md:from-theme-dark pointer-events-none absolute inset-y-0 hidden w-[21.783%] items-center from-[#151515] md:flex end-0 justify-start bg-gradient-to-l rtl:bg-gradient-to-r"></div>
                </div>
            </div>
        </div>
    );
}

export default Banner;
