import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import AvatarUser from '~/assets/icon/AvatarUser';
import LogoMobile from '~/assets/icon/LogoMobile';
import LogoDesktop from '~/assets/icon/LogoDesktop';

function Header() {
    const [value, setValue] = useState(1);
    const [searchParams, setSearchParams] = useSearchParams();

    const app = searchParams.get('app');
    const amount = searchParams.get('amount');
    const channel = searchParams.get('channel');

    const { current_apps } = useSelector((state) => state.app);
    const { auth_account, auth_garena } = useSelector((state) => state.auth);

    const navigate = useNavigate();

    useEffect(() => {
        if (current_apps?.login_type === 'account_id' && auth_account) {
            setValue(2);
            return;
        }
        if (current_apps?.login_type !== 'account_id' && auth_garena) {
            setValue(3);
            return;
        }

        setValue(1);
    }, [current_apps, auth_garena, auth_account]);

    const handleClickLogo = () => {
        let params = null;
        if (app) {
            params = {
                app,
            };
        }
        if (channel) {
            if (app) {
                params = {
                    app,
                    channel,
                };
            }
        }
        if (amount) {
            if (app && channel) {
                params = {
                    app,
                    channel,
                    amount,
                };
            }
        }

        navigate('/');
        if (params) {
            setSearchParams(params);
        }
    };

    return (
        <header className="sticky top-0 z-10 h-14 border-b border-box-border/50 bg-white md:h-[60px]">
            <div className="mx-auto flex h-full w-full max-w-5xl items-stretch justify-between gap-1 px-3 md:px-4">
                <div className="flex items-center gap-2.5 md:gap-3" onClick={handleClickLogo}>
                    <div className="flex items-center cursor-pointer">
                        <LogoMobile />
                        <LogoDesktop />
                        <div className="ms-1.5 h-5 border-e border-[#C1C2C8] md:ms-3 md:h-3.5"></div>
                    </div>
                    <div className="text-xs font-medium text-text-title max-md:max-w-24 md:text-base/5">Trung tâm nạp thẻ Garena</div>
                </div>

                <div className="flex min-w-0 items-stretch">
                    <div className="relative flex items-center">
                        <button className="block rounded-full transition-opacity hover:opacity-70" type="button">
                            <div className="relative h-[30px] w-[30px]">
                                <div className="overflow-hidden rounded-full">
                                    {value === 1 ? (
                                        <AvatarUser />
                                    ) : value === 2 ? (
                                        <img src={auth_account?.img_url} alt={auth_account?.nickname} />
                                    ) : (
                                        <img
                                            src="https://cdngarenanow-a.akamaihd.net/gxx/resource/avatar/0.jpg"
                                            alt={auth_garena?.username}
                                        />
                                    )}
                                </div>
                            </div>
                        </button>
                    </div>
                    <div></div>
                </div>
            </div>
        </header>
    );
}

export default Header;
