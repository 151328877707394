function IconArrowUp() {
    return (
        <svg width="1em" height="1em" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" className="ms-1" role="none">
            <g role="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M62.8284 51.8284C61.2663 53.3905 58.7337 53.3905 57.1716 51.8284L40 34.6569L22.8284 51.8284C21.2663 53.3905 18.7337 53.3905 17.1716 51.8284C15.6095 50.2663 15.6095 47.7337 17.1716 46.1716L37.1716 26.1716C38.7337 24.6095 41.2663 24.6095 42.8284 26.1716L62.8284 46.1716C64.3905 47.7337 64.3905 50.2663 62.8284 51.8284Z"
                    fill="currentColor"
                    role="none"
                ></path>
            </g>
        </svg>
    );
}

export default IconArrowUp;
