import { createSlice } from '@reduxjs/toolkit';

const settingReducer = createSlice({
    name: 'setting',
    initialState: {
        banner_url: '',
        login_id: true,
        login_garena: true,
        website_status: false,
    },
    reducers: {
        dispatchSettings: (state, action) => {
            state.login_id = action.payload.login_id;
            state.banner_url = action.payload.banner_url;
            state.login_garena = action.payload.login_garena;
            state.website_status = action.payload.website_status;
        },
    },
});

export const { dispatchSettings } = settingReducer.actions;

export default settingReducer.reducer;
