import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { requestLoginGarena } from '~/services/auth';
import { loginGarenaSuccess } from '~/redux/reducer/auth';
import { dispatchStartLoading, dispatchStopLoading } from '~/redux/reducer/module';

function LoginGarena() {
    const [error, setError] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const params = new URL(window.location).searchParams;

    const redirect = params.get('redirect');

    const decodedRedirect = decodeURIComponent(redirect);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLoginGarena = async () => {
        if (!username) {
            return setError('Vui lòng nhập Nhập tên tài khoản hoặc email');
        }
        if (!password) {
            return setError('Vui lòng nhập mật khẩu tài khoản');
        }

        dispatch(dispatchStartLoading());
        const data = {
            username,
            password,
        };

        const result = await requestLoginGarena(data);

        dispatch(dispatchStopLoading());
        if (result.status === 200) {
            dispatch(loginGarenaSuccess(result.data));
            navigate(decodedRedirect);
        } else {
            setError(`Đăng nhập thất bại: ${result.message || result.error}`);
        }
    };

    return (
        <main className="form-login-main">
            <div className="form-login-form">
                <h2>Đăng nhập</h2>

                <div className="field required">
                    <input
                        type="text"
                        placeholder="Tài khoản Garena, Email hoặc số điện thoại"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="field required">
                    <input type="password" placeholder="Mật khẩu" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <a className="forgot" href="https://account.garena.com/recovery" target="_blank" rel="noreferrer">
                    Quên mật khẩu?
                </a>
                <div className="field">
                    <button className="primary login" onClick={handleLoginGarena}>
                        Đăng Nhập Ngay
                    </button>
                    {error && <div className="form-login-message-error">{error}</div>}
                </div>
                <div className="field">
                    <a href="https://sso.garena.com/universal/register" target="_blank" rel="noreferrer">
                        <button className="secondary register">Tạo tài khoản mới</button>
                    </a>
                </div>
                <div className="agreement">
                    <a href="https://contentgarena-a.akamaihd.net/legal/tos/tos_vn.html" target="_blank" rel="noreferrer">
                        Điều Khoản Dịch Vụ
                    </a>{' '}
                    và{' '}
                    <a href="https://contentgarena-a.akamaihd.net/legal/pp/pp_vn.html" target="_blank" rel="noreferrer">
                        Chính Sách Bảo Mật
                    </a>
                </div>
            </div>
        </main>
    );
}

export default LoginGarena;
