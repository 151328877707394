import { createSlice } from '@reduxjs/toolkit';

const appReducer = createSlice({
    name: 'app',
    initialState: {
        current_apps: null,
    },
    reducers: {
        dispatchCurrentApps: (state, action) => {
            state.current_apps = action.payload;
        },
    },
});

export const { dispatchCurrentApps } = appReducer.actions;

export default appReducer.reducer;
